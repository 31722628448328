<template>
  <div style="padding: 24px 36px;">
    <div style="width: 1200px" class="mrc">
      <div style="width: 1200px" class="mt-3">
        <img style="width: 100%; height: 502px" src="~@/assets/img/zrytzq/banner-zryt.png"/>
      </div>
      <div class="tac mt-5 mb-3">
        <img src="~@/assets/img/zrytzq/zryt-bxrk.png" height="64px">
      </div>
      <div style="width: 1200px; position: relative; margin: 0px; padding-top: 60px; padding-bottom: initial;">
        <div class="o2_tabcont_header o2_tabcont_header_top">
          <div v-for="(tab, index) in tabs" :key="tab.label" class="o2_tabcont_li o2_tabcont_header_item" :class="currentTab === index ? 'o2_tabcont_li_hover' : ''" @mouseenter="currentTab = index">
            <span class="cp">{{ tab.label }}</span>
          </div>
        </div>
        <div class="o2_tabcont_body">
          <div class="o2_div">
            <div class="o2_goods" style="width: 1200px; position: relative; overflow: hidden; background-color: white">
              <div v-for="data in tabs[currentTab].data" :key="data.id" class="goods_item hover_switch1 goods_item_left goods_item_top" style="padding: 30px 12px 0px; margin: 0px; height: 334px; width: 240px;">
                <div style="display: block">
                  <div style="width: 180px; height: 180px;" class="goods_item_img">
                    <div class="cp" style="background: transparent; width: 180px; height: 180px;" @click="chooseProduct(data.id)">
                      <img :src="require('@/assets/img/zrytzq/' + data.img)" style="width: 100%;height: 100%;opacity: 1;" class="goods_item_img_img"/>
                    </div>
                  </div>
                </div>
                <div class="goods_item_title goods_item_title_two cp fwb" style="font-size: 16px; height: 42px; margin: 15px 5px 10px;" @click="chooseProduct(data.id)">
                  {{ data.name }}
                </div>
                <div class="goods_item_price" style="color: rgb(221, 69, 69); margin: 0px 5px;">
                  ¥<span>{{ data.price }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tac mt-5 mb-3">
        <img src="~@/assets/img/zrytzq/zryt-jxml.png" height="64px">
      </div>
      <el-row :gutter="20">
        <el-col :span="10" style="padding: 0px; min-height: 30px;">
          <div style="padding: 0px; margin: 0px; width: 490px; height: 520px;">
            <div style="background: transparent;width: 100%;height: 100%">
              <img src="~@/assets/img/zrytzq/jxhd-1.png" style="width: auto; height: 100%;" class="cp" @click="$router.push({ name: 'search', params: {itemCode: '2004'} })"/>
            </div>
          </div>
        </el-col>
        <el-col :span="14" style="padding: 0px; min-height: 30px;">
          <div>
            <div style="padding: 0px; margin: 0px; width: 700px; height: 270px;">
              <div style="background: transparent;width: 100%;height: 100%">
                <img src="~@/assets/img/zrytzq/jxhd-2.png" style="width: auto; height: 100%;" class="cp" @click="$router.push({ name: 'search', params: {itemCode: '2005'} })"/>
              </div>
            </div>
          </div>
          <div style="min-height: 30px; padding: 0px; margin: 0px -5px 0px 0px;">
            <div style="box-sizing: border-box;display: flex">
              <div style="padding: 0px 5px 0px 0px; margin: 10px 0px 0px; width: 350px; height: 240px;">
                <div style="background: transparent;width: 100%;height: 100%">
                  <img src="~@/assets/img/zrytzq/jxhd-3.png" style="width: auto; height: 100%;" class="cp" @click="$router.push({ name: 'search', params: {itemCode: '2007'} })"/>
                </div>
              </div>
              <div style="padding: 0px 5px 0px 0px; margin: 10px 0px 0px; width: 350px; height: 240px;">
                <div style="background: transparent;width: 100%;height: 100%">
                  <img src="~@/assets/img/zrytzq/jxhd-4.png" style="width: auto; height: 100%;" class="cp" @click="$router.push({ name: 'search', params: {itemCode: '20'} })"/>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
<!--    <div class="list-box uf uf fwrap-w tal">-->
<!--      <template v-if="dataList.length > 0">-->
<!--        <div class="list" v-for="item in dataList" :key="item.id">-->
<!--          <div class="pic-box" @click="chooseProduct(item)">-->
<!--            <img :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')"/>-->

<!--            <div class="ctx-box">-->
<!--              <div class="f18 ctx-box-price">-->
<!--                <span v-if="item.minPrice !== item.maxPrice && item.minPrice !== -1"><span class="f20">￥</span>{{(item.minPrice || 0).toFixed(2)}} - {{(item.maxPrice || 0).toFixed(2)}}</span>-->
<!--                <span class="f18" v-else-if="item.minPrice === item.maxPrice && item.minPrice !== -1">-->
<!--                      ￥{{(item.minPrice || 0).toFixed(2)}}-->
<!--                    </span>-->
<!--                <span v-else class="f18">面议</span>-->
<!--                <span style="font-size: 5px; color: gray"></span>-->
<!--              </div>-->
<!--              <div class="dec ctx-link fwb">-->
<!--                <span>{{ item.serviceName }}</span>-->
<!--              </div>-->
<!--              <div style="padding: 0 14px">-->
<!--                <div class="f12">-->
<!--                  <span class="op-07">服务分类：{{ item.itemName }}</span>-->
<!--                  <span></span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="company mb-3 mt-1">-->
<!--                <div class="cp uf uf-ac" style="font-size: 10px">-->
<!--                  <div style="padding: 0 7px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">-->
<!--                    自营-->
<!--                  </div>-->
<!--                  <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">-->
<!--                    严选-->
<!--                  </div>-->
<!--                  <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">-->
<!--                    服务质保-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        <div class="tac wi100" style="height: 300px">-->
<!--          <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>-->
<!--          <div class="f14" style="color: #909399">暂无数据</div>-->
<!--        </div>-->
<!--      </template>-->
<!--      <div class="tac wi100 mt-4 mb-2">-->
<!--        <el-pagination-->
<!--          background-->
<!--          @size-change="sizeChangeHandle"-->
<!--          @current-change="currentChangeHandle"-->
<!--          :current-page="pageIndex"-->
<!--          :page-sizes="[10, 20, 50]"-->
<!--          :page-size="pageSize"-->
<!--          :total="totalCount"-->
<!--          layout="total, sizes, prev, pager, next, jumper">-->
<!--        </el-pagination>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
  export default {
    name: 'zryt',
    props: {
      type: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        productType: '',
        dataList: [],
        pageIndex: 1,
        pageSize: 20,
        totalCount: 0,
        totalPage: 0,
        tabs: [
          {
            key: 'tab1',
            label: '能源电池',
            data: [
              {img: '1-1.png', name: '便携式家用电器锂电池检测', price: '50-21850', id: 'faf3fbf3b88849c1bc0df427c6d77c40'},
              {img: '1-2.png', name: '移动电源检测', price: '100-57900', id: '4311cce1f8e54958872adeff591201e7'},
              {img: '1-3.png', name: '锂电池检测', price: '13400', id: '1c4cafb0b5e24eba9ae0344318430e14'},
              {img: '1-4.png', name: '工业用锂电池检测', price: '500-1000', id: '15de618f73fc421787e3c4138e5077fa'},
              {img: '1-5.png', name: '便携式电子产品锂电池检测', price: '50-22480', id: '4e683ad62aef47f29fd869d149d2374c'}
            ]
          },
          {
            key: 'tab2',
            label: '电机及电动工具',
            data: [
              {img: '2-1.png', name: '中小型电动机检测', price: '100-3900', id: '29ef7ea2744146f5bea101e6c7ec7d14'},
              {img: '2-2.png', name: '圆锯检测', price: '36-7299', id: '1f002e08d80440c1afd3d105f0f9f309'},
              {img: '2-3.png', name: '小功率电动机检测', price: '4000', id: '1cfc79c66fe746d7839950d4c47f9c94'},
              {img: '2-4.png', name: '电剪刀和电冲剪检测', price: '60-7460', id: '186bf5279c994025b3d69e2cda8e4724'},
              {img: '2-5.png', name: '电钻和冲击电钻检测', price: '36-7299', id: '3ab55d8b65e44c6baf090338e66a8127'}
            ]
          },
          {
            key: 'tab3',
            label: '家用产品',
            data: [
              {img: '3-1.png', name: '加湿器检测', price: '500-2000', id: '5742f414d68246038fd03b88b621ad86'},
              {img: '3-2.png', name: '口腔卫生器具检测', price: '9-6500', id: '2ae49165bf8a4bc4a1963e313327d6bc'},
              {img: '3-3.png', name: '吸油烟机检测', price: '9-9993', id: '194731f5c2f24b91b3535e1ad0a45ecd'},
              {img: '3-4.png', name: '坐便器检测', price: '500-2000', id: '1496a64c87944319b7d3bed2c38ceb2b'},
              {img: '3-5.png', name: '微波炉检测', price: '400-6000', id: '5a98b93f8fe34296b5577e3a56126cb4'}
            ]
          },
          {
            key: 'tab4',
            label: '大型家电',
            data: [
              {img: '4-1.png', name: '家用电动洗衣机检测', price: '9-9984', id: '2d7a83bceb73415ba880e6bcabfe2e06'},
              {img: '4-2.png', name: '热泵热水器检测', price: '9-17431', id: '714650f838f9468e8652c912a910d033'},
              {img: '4-3.png', name: '电冰箱检测', price: '1080-5130', id: '16ed3c4012464b5a8e5cae1d81642cf4'},
              {img: '4-4.png', name: '空调检测', price: '2000-21000', id: '06845f56740e4649913b26066e59416a'},
              {img: '4-5.png', name: '冷热饮水机检测', price: '900', id: '1ecef7dd5b5e4e34a575c498f067d2ea'}
            ]
          },
          {
            key: 'tab5',
            label: '照明产品',
            data: [
              {img: '5-1.png', name: '36V以下可移式灯具检测', price: '15-4300', id: '30a5f472ed7848b8b957a0a0e10ec2a3'},
              {img: '5-2.png', name: '儿童用可移式灯具检测', price: '1000-3000', id: 'a481c53046fa4e5d9a56194ffc463d18'},
              {img: '5-3.png', name: '可移式灯具检测', price: '10.8-7820', id: '0f7190c6dca84082a6d3b7a17638d62d'},
              {img: '5-4.png', name: '嵌入式灯具检测', price: '20-4400', id: '137520f04e204383b62b8a67477a9e71'},
              {img: '5-5.png', name: '普通照明用LED模块检测', price: '9-8179', id: '0f012821a5ae4448a32e883518c21ee8'}
            ]
          }
        ],
        currentTab: 0
      }
    },
    created () {
    },
    mounted () {
      this.productType = this.type.split('-')[1]
      this.getDataList(true)
    },
    methods: {
      getDataList(refresh) {
        if (refresh) {
          this.pageIndex = 1
        }
        this.$http({
          url: this.$http.adornUrl(this.$api.PUB.ZRYT_PRODUCT),
          methods: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'type': this.productType
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalCount = data.page.totalCount
            this.totalPage = data.page.totalPage
          } else {
            this.dataList = []
            this.totalCount = 0
            this.totalPage = 1
          }
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList(true)
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList(false)
      },
      // 选择产品
      chooseProduct (id) {
        let itemCodeType = 'prefecture'
        this.$router.push({name: 'service', query: {id: id, itemCodeType:itemCodeType}})
      }
    }
  }
</script>

<style scoped>
  .o2_tabcont_header {
    white-space: nowrap;
    overflow: hidden;
  }
  .o2_tabcont_header_top {
    position: absolute;
    width: 100%;
    text-align: left;
    top: 0px;
    bottom: initial;
  }
  .o2_tabcont_li {
    width: 240px;
    height: 60px;
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    line-height: 60px;
    padding-left: 0px;
    border-width: 0px;
    border-radius: 0px;
    cursor: pointer;
    margin-right: 0px;
  }
  .o2_tabcont_li_hover {
    width: 240px;
    height: 60px;
    color: rgb(255, 255, 255);
    background-color: rgb(221, 69, 69);
    line-height: 60px;
    padding-left: 0px;
    border-width: 0px;
    border-radius: 0px;
    cursor: pointer;
    margin-right: 0px;
  }
  .o2_tabcont_header_item {
    position: relative;
    display: inline-block;
    text-align: center;
    background-size: 100% 100%;
    transition: all .2s linear 0s;
    border-style: solid;
  }
  .o2_tabcont_body {
    min-height: 100px;
  }
  .o2_div {
    min-height: 30px;
    padding: 10px 0px 0px;
    margin: 0px;
    background-color: rgb(245, 245, 245);
  }
  .o2_goods .goods_item_top {
    border-top: 1px solid #f2f2f2;
  }
  .o2_goods .goods_item_left {
    border-left: 1px solid #f2f2f2;
  }
  .o2_goods .goods_item {
    position: relative;
    float: left;
    width: 176px;
    height: 230px;
    padding: 6px 12px 0;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    overflow: hidden;
  }
  .o2_goods .goods_item_img {
    margin: 0 auto;
    width: 160px;
    height: 160px;
    text-align: center;
    overflow: hidden;
    transition: transform .2s ease-out;
  }
  .o2_goods .goods_item_img_img {
    transition: opacity .25s ease-in;
  }
  .o2_goods .goods_item_title_two {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .o2_goods .goods_item_title {
    display: block;
    max-width: 100%;
    overflow: hidden;
    font-size: 12px;
    text-align: left;
    color: #333;
    text-overflow: ellipsis;
    line-height: 1.5;
  }
  .o2_goods .goods_item_price {
    font-size: 18px;
    text-align: left;
    color: #fc1934;
    margin-top: 10px;
  }



  .list-box {
    max-width: 1325px;
    margin: 0 auto;
  }
  .list-box .list {
    width: 245px;
    height: auto;
    margin: 10px;
    background: #ffffff;
    line-height: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,.08);
  }
  .list-box .list:hover {
    /*border: 1px solid #409EFF;*/
    box-shadow: 0 8px 15px rgba(0,0,0,.15);
    transform: translate3d(0,-2px,0);
  }
  .list-box .pic-box {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  .list-box .pic-box img {
    width: 100%;
    height: 190px;
  }
  .list-box .ctx-box .dec{
    padding: 0 14px;
    /*margin: 10px 0;*/
    font-size: 16px;
    overflow: hidden;
    cursor: pointer;
    /*height: 40px;*/
  }
  .ctx-link:hover{
    color: #3987dd;
    text-decoration: underline;
    opacity: 1;
  }
  .ctx-box-price{
    padding: 18px 14px 0 10px;
    color: #fe3b25;
  }
  .list-box .ctx-box .company {
    padding: 0 12px;
    margin-top: 8px;
  }
  .detail-box {
    /*width: 1180px;*/
    /*margin: 0 auto;*/
  }
  .detail-box .logo {
    width: 300px;
    height: 300px;
  }
  .detail-box .detail .label {
    font-size: 12px;
    opacity: .6;
    width: 110px;
  }
</style>
